<template>
  <div class="home">
    <div class="b-wrap">
      <div class="banner">
        <el-carousel type="card">
          <el-carousel-item v-for="(item,index) in banner" :key="index" >
           <el-image :src="item.imgUrl" style="height: 300px;"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <h4 class="cate">抖音/快手</h4>
      <el-row :gutter="12">
        <el-col :xs="24" :sm="12" :md="4" :lg="4" v-for="(item,index) in listData" :key="index" class="good-item" >
          <el-card :body-style="{ padding: '0px' }" shadow="hover" :key="index">
            <div class="p-img" @click="handleDetail(item)">
              <el-image
                style="width: 100%; height: 220px"
                :src="item.imgurl"
                fit="cover"></el-image>
            </div>
            <div @click="handleDetail(item)">
            <div class="p-price">
              <strong>
                <em>￥</em>{{item.saleprice}}
              </strong>
            </div>
            <div class="p-name">
              <em>{{item.productname}}</em>
            </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { list } from '@/api/index.js'
import ks from '../assets/ks.jpeg'
import xhs from '../assets/xhs.jpeg'
import dy from '../assets/Douyin_1.jpeg'

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      banner: [{
        imgUrl: dy
      },
      {
        imgUrl: ks
      }, {
        imgUrl: xhs
      }],
      listData: [],
      brandId: ''
    }
  },
  created () {
    this.handleList()
    this.brandId = this.$route.params.brandId
  },
  methods: {
    async handleList () {
      const { data, code } = await list()
      if (code === 20000) {
        this.listData = data
      }
    },
    handleDetail (item) {
      const brandId = this.brandId
      const id = item.productId
      this.$router.push({ path: `/detail/${brandId}/${id}`, query: item })
    }
  },
  mounted () {
  }
}
</script>
<style lang="less">
.home {
  margin: 0;
  padding: 0;
}
.b-wrap {
  box-sizing: border-box;
  width: 1630px;
  margin: 0 auto;
}

@media screen and (max-width: 1870px) {
  .b-wrap {
    width:1414px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1654px) {
  .b-wrap {
    width:1198px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1438px) {
  .b-wrap {
    width:999px;
    margin: 0 auto;
  }
}
@media (max-width: 992px) {
  .b-wrap {
    width:100%;
    padding: 20px;
  }
  .banner {
    display: none;
  }
}
.p-img {
  height: 220px;
  padding: 0;
  margin-bottom: 15px;
  position: relative;
  img {
    border: 0;
    vertical-align: middle;
  }
}
.p-price {
  position: relative;
  line-height: 22px;
  height: 22px;
  overflow: hidden;
  width: 100%;
  margin: 0 0 8px;
  padding-left: 10px;
  strong {
    float: left;
    margin-right: 10px;
    color: #e4393c;
    font-size: 20px;
    em {
      font-size: 16px
    }
  }
}
.p-name {
  height: 40px;
  margin-bottom: 8px;
  overflow: hidden;
  font-size: 12px;
  color: #666;
  padding-left: 10px;
  em{
    display: inline;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    *cursor: pointer;
    transition: height .08s ease
  }
}
.p-commit {
  width: 100%;
  height: 18px;
  margin-top: -3px;
  margin-bottom: 9px;
  overflow: hidden;
  color: #a7a7a7;
  font-weight: 400;
  font-size: 12px;
  padding-left: 10px;
  strong {
    color: #646fb0;
    font-weight: 700;
  }
}
.good-item {
  margin-bottom: 12px;
  cursor: pointer;
}
.banner {
  margin: 20px 0;
}
.cate {
  margin-bottom: 20px;
}
</style>
