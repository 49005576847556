import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button, Select, Row, Col, Card, Image, Form,
  FormItem,
  Option,
  OptionGroup,
  ButtonGroup, Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Breadcrumb,
  BreadcrumbItem,
  Tabs,
  TabPane,
  Tag,
  Carousel,
  CarouselItem,
  Result,
  Dialog
} from 'element-ui'
import 'normalize.css/normalize.css'

Vue.use(Button)
Vue.use(Select)
Vue.use(Row)
Vue.use(Card)
Vue.use(Col)
Vue.use(Image)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(ButtonGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Result)
Vue.use(Dialog)
Vue.config.productionTip = false
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 } // a modern alternative to CSS resets

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
