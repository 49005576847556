<template>
  <div id="app">
    <transition name="fade-transform" mode="out-in">
    <router-view/>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="less">
#app {
  margin: 0;
  padding: 0;
}
a,address,b,big,blockquote,body,center,cite,code,dd,del,div,dl,dt,em,fieldset,font,form,h1,h2,h3,h4,h5,h6,html,i,iframe,img,ins,label,legend,li,ol,p,pre,small,span,strong,u,ul,var {
  margin: 0;
  padding: 0
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {
  display: block
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0
}

button,html input[type=button],input[type=submit] {
  -webkit-apperance: button;
  cursor: pointer
}

button[disabled],html input[disabled] {
  cursor: default
}

ol,ul {
  list-style: none
}

img {
  border: 0;
  vertical-align: middle
}

em,i,u {
  font-style: normal
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
